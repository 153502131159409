import { render, staticRenderFns } from "./dualWallet.vue?vue&type=template&id=473cb3aa&scoped=true&"
import script from "./dualWallet.vue?vue&type=script&lang=js&"
export * from "./dualWallet.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/dualWallet.less?vue&type=style&index=0&id=473cb3aa&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "473cb3aa",
  null
  
)

export default component.exports